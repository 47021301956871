//Dual Branded Logo Styling
.brand__lockup img {
  max-width: 456px;
  display: block;
  margin: 0 auto;
}

/*HOME PAGE*/
/*CTA*/
.homepage-h4,
.homepage-h6 {
  color: $wcm-red !important;
  margin-bottom: -45px;
}

.thumbnail-controller {
  display: none;
}

.left {
  text-align: left;
  list-style-type: none;
}

/*REMOVED LINK GLIPH ON HOMEPAGE LINKS*/
.front .site-main .fieldable-panels-pane .external-link:after {
  content: '';
  display: block;
}

/*COLUMNS*/
$three-col-margin-left: 2% 2% 0% .10%;
$three-col-margin-center: 2% 0% 0% 0%;
$three-col-margin-right: 2% .10% 0% 2%;
$three-col-width: 31.9%;
$two-col-width: 49%;

/*ROW2*/
@mixin row2-dim {
  width: $three-col-width;
  height: 220px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid $wcm-border-gray;
  box-shadow: 1px 1px 10px #888888;
}

.row2-left {
  @include row2-dim;
  clear: left;
  margin: $three-col-margin-left;
}

.row2-center {
  @include row2-dim;
  margin: $three-col-margin-center;
}

.row2-right,
.view-display-id-panel_pane_6 {
  @include row2-dim;
  margin: $three-col-margin-right;
}

.view-display-id-panel_pane_6 li {
  padding-left: 40px;
}

/*ROW3*/
@mixin row3-dim {
  width: $three-col-width;
  height: 100px;
  min-height: 100px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid $wcm-border-gray;
  box-shadow: 1px 1px 10px #888888;
}

.row3-left {
  @include row3-dim;
  clear: left;
  margin: $three-col-margin-left;
}

.row3-center {
  @include row3-dim;
  position: static;
  margin: $three-col-margin-center;
}

.row3-right {
  @include row3-dim;
  margin: $three-col-margin-right;
}

/*ROW TWO-COLUMN*/
.row-two-column-left {
  width: 49%;
  height: 100px;
  min-height: 100px;
  margin: 2% 1% 0% 0%;
  padding: 1.5% 0% 0% 0%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid $wcm-border-gray;
  box-shadow: 1px 1px 10px #888888;
}

.row-two-column-right {
  width: 49%;
  height: 100px;
  min-height: 100px;
  margin: 2% 0% 0% 1%;
  padding: 0%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid $wcm-border-gray;
  box-shadow: 1px 1px 10px #888888;
}

/*TABLET/MOBILE*/

@media (max-width : 991px) {

  .row2-left,
  .row2-center,
  .row2-right,
  .view-display-id-panel_pane_6 {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0px !important;
    border: 1px solid $wcm-border-gray;
    box-shadow: 0px 0px 0px 0px $wcm-white;
    padding-left: 0px;
  }

  .row3-left,
  .row3-center,
  .row3-right {
    width: 100%;
    height: 35px;
    margin: 0px !important;
    border: 1px solid $wcm-border-gray;
    box-shadow: 0px 0px 0px 0px $wcm-white;
  }

  .row-two-column-left,
  .row-two-column-right {
    width: 100%;
    box-shadow: none;
    margin: 0;
  }
}

/* Carousel */
.overlay-text {
  opacity: 1 !important;
}
