/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Responsive iFrames
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/*NAV OVERRIDE*/
/*Level 1 Nav*/
#primary-nav .level-1 {
  width: auto !important; }
  @media screen and (min-width: 768px) {
    #primary-nav .level-1 {
      padding: 8px 22px; } }
  @media screen and (min-width: 992px) {
    #primary-nav .level-1 {
      padding: 8px 37px; } }
  @media screen and (min-width: 1200px) {
    #primary-nav .level-1 {
      padding: 8px 57px !important; } }

/*Level 2 Nav*/
#drawer-nav .level-2 {
  width: 16.5%;
  padding: 10px 10px; }

/*Level 3 Nav*/
#drawer-nav .level-3 {
  list-style-type: disc;
  margin: 5px 0 0 10px;
  font-size: 13px;
  font-weight: 400;
  width: 100%; }

/*Footer Nav*/
.block-menu-block .content {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

.brand__lockup img {
  max-width: 456px;
  display: block;
  margin: 0 auto; }

/*HOME PAGE*/
/*CTA*/
.homepage-h4,
.homepage-h6 {
  color: #b31b1b !important;
  margin-bottom: -45px; }

.thumbnail-controller {
  display: none; }

.left {
  text-align: left;
  list-style-type: none; }

/*REMOVED LINK GLIPH ON HOMEPAGE LINKS*/
.front .site-main .fieldable-panels-pane .external-link:after {
  content: '';
  display: block; }

/*COLUMNS*/
/*ROW2*/
.row2-left {
  width: 31.9%;
  height: 220px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  clear: left;
  margin: 2% 2% 0% 0.1%; }

.row2-center {
  width: 31.9%;
  height: 220px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  margin: 2% 0% 0% 0%; }

.row2-right,
.view-display-id-panel_pane_6 {
  width: 31.9%;
  height: 220px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  margin: 2% 0.1% 0% 2%; }

.view-display-id-panel_pane_6 li {
  padding-left: 40px; }

/*ROW3*/
.row3-left {
  width: 31.9%;
  height: 100px;
  min-height: 100px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  clear: left;
  margin: 2% 2% 0% 0.1%; }

.row3-center {
  width: 31.9%;
  height: 100px;
  min-height: 100px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  position: static;
  margin: 2% 0% 0% 0%; }

.row3-right {
  width: 31.9%;
  height: 100px;
  min-height: 100px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  margin: 2% 0.1% 0% 2%; }

/*ROW TWO-COLUMN*/
.row-two-column-left {
  width: 49%;
  height: 100px;
  min-height: 100px;
  margin: 2% 1% 0% 0%;
  padding: 1.5% 0% 0% 0%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888; }

.row-two-column-right {
  width: 49%;
  height: 100px;
  min-height: 100px;
  margin: 2% 0% 0% 1%;
  padding: 0%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888; }

/*TABLET/MOBILE*/
@media (max-width: 991px) {
  .row2-left,
  .row2-center,
  .row2-right,
  .view-display-id-panel_pane_6 {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0px !important;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 0px 0px #fff;
    padding-left: 0px; }
  .row3-left,
  .row3-center,
  .row3-right {
    width: 100%;
    height: 35px;
    margin: 0px !important;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 0px 0px #fff; }
  .row-two-column-left,
  .row-two-column-right {
    width: 100%;
    box-shadow: none;
    margin: 0; } }

/* Carousel */
.overlay-text {
  opacity: 1 !important; }

/*PULL-DOWN MENU CUSTOM WIDTH*/
.select2-container--default {
  min-width: 300px !important; }

/*CONTACT PAGE TABLE LAYOUT*/
.contact_left {
  float: left;
  width: 30%; }

.contact_right {
  float: left;
  width: 70%; }

@media (max-width: 768px) {
  .contact_left {
    width: 100%; }
  .contact_right {
    width: 100%;
    padding-bottom: 15px;
    clear: both; } }

/*DEFAULT IMAGE PADDING*/
.panopoly-image-original {
  padding: 40px 30px; }

/*FACULTY*/
.facultyImg {
  border-top: 8px solid #b31b1b;
  margin-bottom: 5px;
  overflow: hidden; }
  .facultyImg img {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 3/4;
    object-fit: cover;
    object-position: center; }

.facultyName a {
  font-weight: bold; }

.view-our-faculty .views-exposed-form {
  margin-bottom: 30px; }

.view-our-faculty .grid-item {
  margin-bottom: 30px; }

/*NEWS*/
.medium {
  float: left;
  margin: 30px 40px; }

.news-featured-image {
  max-width: 400px;
  width: 100%; }

.view-id-news .panopoly-image-original {
  padding: 40px 30px; }

/*Button Float Left*/
.btn--wcm-left {
  position: relative;
  display: inline-block;
  width: 220px;
  margin: 10px auto;
  border-radius: 20px;
  border-width: 4px;
  font-size: 13px;
  color: #cf4520;
  background-color: transparent;
  border-color: #e7751d; }

.hidden_anchor {
  opacity: 0;
  margin-left: -20px; }
