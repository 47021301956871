/*FACULTY*/
.facultyImg {
  border-top: 8px solid $wcm-red;
  margin-bottom: 5px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 3/4;
    object-fit: cover;
    object-position: center;
  }
}

.facultyName a {
  font-weight: bold;
}

.view-our-faculty {
  .views-exposed-form {
    margin-bottom: 30px;
  }
  .grid-item {
    margin-bottom: 30px;
  }
}
