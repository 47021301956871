/*NEWS*/
.medium{
    float: left;
    margin: 30px 40px;
}
 
.news-featured-image{
  max-width: 400px;
  width: 100%;
}

.view-id-news .panopoly-image-original{
    padding: 40px 30px;
}

/*Button Float Left*/
.btn--wcm-left{
    position: relative;
    display: inline-block;
    width: 220px;
    margin: 10px auto;
    border-radius: 20px;
    border-width: 4px;
    font-size: 13px;
    color: $wcm-dark-orange;
    background-color: transparent;
    border-color: $wcm-bright-orange;
}

.hidden_anchor{
    opacity: 0;
    margin-left: -20px;
}