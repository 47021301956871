/*NAV OVERRIDE*/
/*Level 1 Nav*/

#primary-nav .level-1 {
    width: auto !important;
    @include breakpoint($sm){
        padding: 8px 22px;
        }

    @include breakpoint($md){
        padding: 8px 37px;
        }

    @include breakpoint($lg){
        padding: 8px 57px !important;
        }
    }

/*Level 2 Nav*/
    #drawer-nav .level-2 {
        width: 16.5%;
        padding: 10px 10px;
        }

/*Level 3 Nav*/
    #drawer-nav .level-3 {
        list-style-type: disc;
        margin: 5px 0 0 10px;
        font-size: 13px;
        font-weight: 400;
        width: 100%;
        }

/*Footer Nav*/
.block-menu-block .content {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
    }
