/*PULL-DOWN MENU CUSTOM WIDTH*/
.select2-container--default {
      min-width: 300px !important;
  }


/*CONTACT PAGE TABLE LAYOUT*/
.contact_left{
	float: left;
	width: 30%;
}

.contact_right{
	float: left;
	width: 70%;
}

@media (max-width : 768px){
	.contact_left{
		width: 100%;
	}
	.contact_right{
		width: 100%;
		padding-bottom: 15px;
		clear: both;		
	}
}

/*DEFAULT IMAGE PADDING*/
.panopoly-image-original{
	padding: 40px 30px;
}